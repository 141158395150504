import BidSalesService from 'services/timedSales/buyside/bidsales.service';
import {
	offerSummaryFetchError,
	offerSummaryFetchInit,
	setNewBidSalesArray,
} from 'store/slices/offerSummary/buyerOfferSummary';
import { offerSummaryBidSaleIdsAndVersion } from './offerSummarySelector';

export const loadBuyerInitialOfferSummary =
	({ loggedUser }) =>
	async (dispatch, getState) => {
		try {
			const {
				dealership_id: dealershipId,
				dealerships_where_user_is_master_buyer: dealershipsWhereUserIsMasterBuyer,
			} = loggedUser;
			const dealershipsId =
				!dealershipsWhereUserIsMasterBuyer?.length ? [dealershipId] : dealershipsWhereUserIsMasterBuyer;
			dispatch(offerSummaryFetchInit({ dealershipsId }));

			const existingSummaryVersion = offerSummaryBidSaleIdsAndVersion(getState());

			const { data } = await BidSalesService.instance.offerRefresh({
				vehicles: [],
				loggedUser,
				focussedSearchListKey: 'auction',
				reason: 'first_load',
				includeSummary: true,
				summaryVersions: existingSummaryVersion,
			});

			const summary = data.data.summary;

			dispatch(setNewBidSalesArray({ bidSalesArr: summary }));
		} catch {
			dispatch(offerSummaryFetchError());
		}
	};
