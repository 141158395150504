import STATUS_CODES from 'constants/statusCodes';
import BuyerOffersService from 'services/marketplace/buyside/offers.service';
import { loadBuyerInitialOfferSummary } from 'store/slices/offerSummary/index';

export const getRefreshDataForVehicleIds = async ({
	vehicles,
	loggedUser,
	searchListKey,
	isFeaturedVehicleCampaign,
	includeSummary,
	dispatch,
}) => {
	if (!vehicles.length) return [];

	const offerRefreshResult = await BuyerOffersService.instance.offerRefresh({
		vehicles,
		loggedUser,
		focussedSearchListKey: searchListKey,
		reason: 'first_load',
		forceRefresh: true,
		isFeaturedVehicleCampaign,
		includeSummary: false, // turn on once https://app.shortcut.com/backlotcars/story/221629 is ready
	});
	if (offerRefreshResult?.status !== STATUS_CODES.OK || !offerRefreshResult?.data?.success) {
		throw new Error('Not able to get refreshed data for vehicles');
	}

	const bidSaleIdToVehicleMap = offerRefreshResult.data.data.vehicles.reduce(
		(accumulator, currentValue) => ({
			...accumulator,
			[currentValue.bid_sale_id]: currentValue,
		}),
		{},
	);

	if (dispatch && includeSummary) {
		// TODO: once https://app.shortcut.com/backlotcars/story/221629 is ready we can use this data from offer_summary
		// && offerRefreshResult.data.data.summary
		// dispatch update offerSummary(offerRefreshResult.data.data.summary);
		dispatch(loadBuyerInitialOfferSummary({ loggedUser }));
	}

	return vehicles.map((vehicle) => ({
		...vehicle,
		...bidSaleIdToVehicleMap[vehicle.bid_sale_id],
	}));
};
